.team-section {
  padding: 90px 0px 40px;
  @media (max-width: $sm-tablet) {
    padding: 60px 0px 24px;
  }
}
.team-section-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  .team-content {
    width: 50%;
    .royal-label {
      margin-bottom: 26px;
    }
    .royal-title {
      margin-bottom: 20px;
      font-size: 38px;
    }
    .normal-text {}
  }
  .team-image {
    width: 50%;
    padding-left: 40px;
    img {
      max-width: 370px;
      width: calc(50% - 20px);
      display: inline-block;
      &:first-child {
        margin-right: 40px;
        transform: translateY(0px);
      }
      &:last-child {
        transform: translateY(-50px);
      }
    }
  }

  @media (max-width: $sm-tablet) {
    display: block;
    .team-content {
      width: 100%;
    }
    .team-image {
      padding-left: 0px;
      width: 100%;
      margin-bottom: 24px;
      img {
        max-width: auto;
      }
    }
  }
}

// animation
.team-section {
  .team-image img {
    width: 245px;
    height: 310px;
    object-fit: cover;
    object-position: center;
    border-radius: 24px;
    &:first-child {
      transition: .4s;
      transition-delay: .6s;
      transition-property: opacity, transform;
      opacity: 0;
      visibility: hidden;
      transform: translateY(100px);
    }
    &:last-child {
      transition: .4s;
      transition-delay: .6s;
      transition-property: opacity, transform;
      opacity: 0;
      visibility: hidden;
      transform: translateY(-100px);
    }
  }
  .team-content {
    transition: .4s;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-150px);
  }

  &.active {
    .team-image img {
      &:first-child {
        transition: .4s;
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
      }
      &:last-child {
        transition: .4s;
        opacity: 1;
        visibility: visible;
        transform: translateY(-50px);
      }
    }
    .team-content {
      transition: .4s;
      opacity: 1;
      visibility: visible;
      transform: translateX(0px);
    }
  }
}