.services-section {
  padding: 90px 0px 0px;
  text-align: center;
  background-color: $background;
  min-height: 100vh;
  .royal-label {
    margin-bottom: 16px;
  }
  .royal-title {
    margin-bottom: 16px;
    font-size: 48px;
  }
  .normal-text {
    margin-bottom: 40px;
  }

  .services-list {
    display: flex;
    .services-list-wrap {
      display: flex;
      flex-wrap: wrap;
    }
    .services-item {
      position: relative;
      padding: 10px;
      overflow: hidden;
      .services-item-inner {
        border-radius: 16px;
        overflow: hidden;
        height: 100%;
      }
      @media (max-width: $mobile) {
        margin-bottom: 24px;
        .services-item-inner {
          height: auto;
          overflow: hidden;
          img { 
            border-radius: 24px;
            position: absolute;
            top: 0; left: 0;
          }
          &::before {
            content: '';
            display: block;
            padding-top: 75%;
          }
        }
      }
      &:hover {
        .services-item-inner img {
          transition: .4s;
          transform: scale(1.1);
        }
      }
      img {
        transition: .4s;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      .text {
        text-align: center;
        color: white;
        font-size: 28px;
        font-weight: 700;
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        &.topleft {
          top: 40px; left: 40px;
          transform: translate(0, 0);
          @media (max-width: $mobile) {
            top: 50%; left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        @media (max-width: $md-mobile) {
          font-size: 20px;
        }
      }
      &.overlay:before {
        content: '';
        position: absolute;
        top: 0; left: 0;
        height: 100%;
        width: 100%;
        display: block;
        background-color: rgba(0,0,0, 0.6);
        z-index: 1;
      }
    }

    @media (max-width: $tablet) {
      flex-wrap: wrap;
    }
    @media (max-width: $md-mobile) {
      padding: 24px 0px;
    }
  }

  // animation
  .services-head {
    opacity: 0;
    transform: .4s;
  }
  .services-list .services-list-wrap {
    opacity: 0;
    visibility: hidden;
    transition: .4s;
    &:nth-child(1) {
      transform: translateY(100px);
    }
    &:nth-child(2) {
      transform: translateY(150px);
    }
    &:nth-child(3) {
      transform: translateY(200px);
    }
  }

  &.active {
    $trsVal: .2s;
    .services-head {
      opacity: 1;
      visibility: visible;
      transition: $trsVal;
    }
    .services-list .services-list-wrap {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
      &:nth-child(1) {
        transition: $trsVal * 1+1;
      }
      &:nth-child(2) {
        transition: $trsVal * 2+1;
      }
      &:nth-child(3) {
        transition: $trsVal * 3+1;
      }
    }
  }
}