.about-section {
  padding: 90px 0px 0px;
  background-color: $background;
  min-height: 100vh;
  .about-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    flex-direction: row-reverse;
    .info-content {
      transform: translateX(-50px);
      opacity: 0;
      transition: .4s;
      .royal-label {
        margin-bottom: 16px;
      }
      .royal-title {
        margin-bottom: 20px;
        font-size: 40px;
      }
      p {
        font-size: 16px;
        color: white;
      }
    }
    .info-image {
      transform: translateX(50px);
      opacity: 0;
      transition: .2s;
      width: 100%;
      img {
        display: block;
        width: 100%;
      }
    }
  }
  .about-expertise {
    .label {
      font-size: 16px;
      margin-bottom: 28px;
      color: #fff;
      opacity: 0;
      transition: .4s;
    }
    .expertise-list {
      display: flex;
      gap: 24px;
      flex-wrap: wrap;
      .about-expertise-box {
        opacity: 0;
        transition: opacity 0.4s ease-in;
        transform: translateY(150px);
        &:nth-child(1) {
          transition-delay: .4s * 1;
        }
        &:nth-child(2) {
          transition-delay: .4s * 2;
        }
        &:nth-child(3) {
          transition-delay: .4s * 3;
        }
        &:nth-child(4) {
          transition-delay: .4s * 4;
        }
        &:nth-child(5) {
          transition-delay: .4s * 5;
        }
        &:nth-child(6) {
          transition-delay: .4s * 6;
        }
        transition-property: opacity;
      }
    }
  }
  @media (max-width: $sm-tablet) {
    .about-info {
      .info-content {
        width: calc(100% - 300px);
      }
      .info-image {
        width: 300px;
      }
    }
  }
  @media (max-width: $mobile) {
    .about-info {
      display: block;
      .info-content {
        width: 100%;
      }
      .info-image {
        width: 100%;
        max-width: 500px;
        margin: 0 auto 20px;
      }
    }
  }
  @media (max-width: $md-mobile) {
    padding: 24px 0px;
  }
  &.active {
    .info-content,
    .info-image {
      transform: translateX(0);
      opacity: 1;
      transition: .4s;
    }
  }
  &.active-expt {
    .about-expertise {
      .label {
        opacity: 1;
        transition-delay: .4s;
        transition: .4s;
      }
      .expertise-list .about-expertise-box {
        $transitionVal: .4s;
        transform: translateY(0);
        opacity: 1;
        &:nth-child(1) {
          transition-delay: .2s * 1;
          transition-property: transform, opacity;
        }
        &:nth-child(2) {
          transition-delay: .2s * 2;
          transition-property: transform, opacity;
        }
        &:nth-child(3) {
          transition-delay: .2s * 3;
          transition-property: transform, opacity;
        }
        &:nth-child(4) {
          transition-delay: .2s * 4;
          transition-property: transform, opacity;
        }
        &:nth-child(5) {
          transition-delay: .2s * 5;
          transition-property: transform, opacity;
        }
        &:nth-child(6) {
          transition-delay: .2s * 6;
          transition-property: transform, opacity;
        }
      }
    }
  }
}

.about-expertise-box {
  position: relative;
  width: calc(33% - 24px);
  border-radius: 16px;
  overflow: hidden;
  @media (max-width: $mobile) {
    
  }
  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    transition: .4s;
  }
  &::before {
    content: '';
    display: block;
    background-color: rgba(0,0,0, 0.5);
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    z-index: 2;
  }
  .text {
    text-align: left;
    min-width: 40%;
    position: absolute;
    top: 50%;
    left: 70%;
    font-weight: 700;
    transform: translate(-70%, -50%);
    color: white;
    z-index: 3;
  }
  &:hover {
    img {
      transition: .4s;
      transform: scale(1.1);
    }
  }
  @media (max-width: $sm-tablet) {
    width: calc(50% - 24px);
  }
  @media (max-width: $mobile) {
    width: 100%;
  }
}