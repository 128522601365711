.experience-section {
  padding: 90px 0px 0px;
  min-height: 100vh;
  @media (max-width: $md-mobile) {
    padding: 80px 0px 0px;
  }
}

.experience-inner {
  .royal-label {
    margin-bottom: 16px;
  }
  .royal-title {
    font-size: 48px;
    margin-bottom: 40px;
  }
}

.experience-list {
  display: flex;
  .w-40 {
    width: calc(50% - 25px);
  }
  @media (max-width: $sm-tablet) {
    display: block;
    .w-40 {
      width: 100%;
    } 
  }
}

.experience-box {
  padding: 28px 40px;
  border-radius: 20px;
  background-color: #13122A;
  margin-right: 24px;
  margin-bottom: 24px;
  img {
    width: 25px;
    margin-bottom: 8px;
  }
  .text {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 12px;
    color: #fff;
  }
  ul {
    padding-left: 20px;
    li {
      color: #fff;
      margin-bottom: 8px;
      font-size: 16px;
    }
  }

  @media (max-width: $sm-tablet) {
    margin-right: 0px;
  }
}

// animation
.experience-section {
  .experience-head {
    transform: translateX(-100px);
    opacity: 0;
    visibility: hidden;
    transition: .4s;
  }
  .experience-list .w-40 {
    &:first-child {
      transform: translateX(-100px);
      opacity: 0;
      visibility: hidden;
      transition: .4s;
    }
    &:last-child {
      transform: translateX(100px);
      opacity: 0;
      visibility: hidden;
      transition: .4s;
    }
  }

  &.active {
    .experience-head {
      transform: translateX(0px);
      opacity: 1;
      visibility: visible;
      transition: .2s;
    }
    .experience-list .w-40 {
      &:first-child {
        transform: translateX(0px);
        opacity: 1;
        visibility: visible;
        transition: .6s;
      }
      &:last-child {
        transform: translateX(0px);
        opacity: 1;
        visibility: visible;
        transition: .6s;
      }
    }
  }
}