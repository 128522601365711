.hero-banner {
  min-height: calc(90vh + 100px);
  padding-top: 100px;
  height: 90vh;
  position: relative;
  .main,
  .main .container {
    height: 100%;
  }
  .royal-label {
    margin-bottom: 16px;
  }
  .hero-banner-background {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: scale(1.2);
    @media (max-width: 1090px) {
      transform: scale(1.5);
    }
    @media (max-width: 890px) {
      transform: scale(2);
    }
    @media (max-width: 675px) {
      transform: scale(2.5);
    }
    @media (max-width: 530px) {
      transform: scale(3);
    }
    @media (max-width: 420px) {
      transform: scale(3.5);
    }
    @media (max-width: 360px) {
      transform: scale(4);
    }
  }
  .banner-head {
    width: 100%;
    max-width: 500px;
    right: 0;
    position: absolute;
    text-align: right;
    img, svg {
      width: 100%;
      max-width: 470px;
    }
    svg {
      display: block;
    }
    .banner-img {
      display: none;
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: 0; left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(7, 6, 30, 0.75);
    z-index: 0;
  }
  .hero-content {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .banner-content {
      z-index: 6;
    }
    
    .scroll-down-wrap {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background-color: white;
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: .2s;
      z-index: 3;
      &:hover {
        transition: .2s;
        background-color: rgba(255,255,255, 0.80);
      }
      img {
        @keyframes arrowDown {
          0% {
            transform: translateY(0px);
          }
          50% {
            transform: translateY(5px);
          }
          100% {
            transform: translateY(0px);
          }
        }

        
        animation-name: arrowDown;
        animation-duration: 1s;
        animation-iteration-count: infinite;
      }
    }

    @media (max-width: $sm-tablet) {
      .royal-label {
        font-size: 16px;
      }
      .royal-title {
        font-size: 44px;
      }
    }
  }

  @media (max-width: $sm-tablet) {
    .banner-head {
      position: relative;
      width: 100%;
      margin-bottom: 40px;
      .banner-img {
        display: block !important;
        transform: translateX(40px);
      }
      svg {
        display: none !important;
      }
    }
    .banner-content {
      position: relative;
      width: 100%;
    }
    .hero-content {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: auto;

      .scroll-down-wrap {
        left: 0;
        transform: translateX(0);
        bottom: -40px;
        position: relative;
      }
    }
  }
  @media (max-width: $mobile) {
    .hero-content {
    }
  }
}