
header {
  // background-color: #060B12;
  background-color: transparent;
  padding-top: 26px;
  position: fixed;
  top: 0; left: 0;
  z-index: 9;
  width: 100%;
  transition: .4s;
  
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    top: 0px;
    position: relative;
  }
  .logo-wrap {}
  .menu-wrap {
    ul {
      display: flex;
      li {
        list-style: none;
        a {
          text-decoration: none;
          padding: 10px;
          display: inline-block;
          margin-left: 20px;
          transition: .2s;
          color: white;
          &:hover {
            font-weight: 500;
            transition: .2s;
          }
        }
      }
    }
  }
  &.active {
    transition: .4s;
    padding-top: 0px;
    background-color: rgba(7, 6, 30, 0.75);
  }
  .btn-mobile {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    display: none;
  }
  .btn11{
    $dark: #fff;
    $light: #efefef;
    $color: #b3e5fc;

    @mixin btn($left, $top) {
      position: absolute;
      width: 60px;
      height: 60px;
      transition-duration: 0.5s;
    }

    @mixin icon($height, $width) {
      transition-duration: 0.5s;
      position: absolute;
      height: $height;
      width: $width;
      top: 30px;
      background-color: $dark;
    }

    @mixin icon-before($height, $width, $top) {
      transition-duration: 0.5s;
      position: absolute;
      width: $width;
      height: $height;
      background-color: $dark;
      content: "";
      top: $top;
    }

    @mixin icon-after($height, $width, $top) {
      transition-duration: 0.5s;
      position: absolute;
      width: $width;
      height: $height;
      background-color: $dark;
      content: "";
      top: $top;
    }

    @mixin icon-circle($height, $width) {
      transition-duration: 0.5s;
      position: absolute;
      height: $height;
      width: $width;
      top: 2px;
      border-radius: 100%;
      background: transparent;
      border: 4px solid black;
      left: -15px;
    }

    @include btn(240px, 300px);
    
    .icon-left {
      @include icon(4px, 15px);
      
      &:before{
        @include icon-before(4px, 15px, -10px);
      }
      
      &:after{
        @include icon-after(4px, 15px, 10px);
      }
      
      &:hover{
        cursor: pointer;
      }
    }
    
    .icon-right {
      @include icon(4px, 15px);
      left: 15px;
      
      &:before{
        @include icon-before(4px, 15px, -10px);
      }
      
      &:after{
        @include icon-after(4px, 15px, 10px);
      }
    }
    
    &.open {
      .icon-left {
        transition-duration: 0.5s;
        background: transparent;
        
        &:before{
          transform: rotateZ(45deg) scaleX(1.4) translate(2px, 2px);
        }
  
        &:after{
          transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -2px);
        }
      }
      
      .icon-right {
        transition-duration: 0.5s;
        background: transparent;  
        
        &:before{
          transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 2px);
        }
  
        &:after{
          transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -2px);
        }
      }
    }
    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: $tablet) {
    .menu-wrap {
      display: none;
    }
    .btn-mobile {
      display: block;
    }
  }
}
.head-mobile-navigation {
  background-color: rgba(7, 6, 30, 0.75);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 8;
  transform: translateX(100%);
  transition: .4s;
  transition-delay: .3s;
  .main {
    background-color: rgba(7, 6, 30, 1);
    height: 100%;
    color: white;
    padding-top: 110px;
    transition: .4s;
    transform: translateX(100%);
  }
  &.active {
    transition: .4s;
    transform: translateX(0);
    .main {
      transition: .4s;
      transition-delay: .3s;
      transform: translateX(0);
    }
  }
  ul {
    text-align: center;
    padding-top: 40px;
    li {
      list-style: none;
      color: white;
      margin-bottom: 40px;
      a {
        color: white;
        font-size: 24px;
        font-weight: 700;
        text-decoration: none;
      }
      .btn {
        font-size: 18px
      }
    }
  }
}

footer {
  background-color: #060B12;
  padding: 40px 0px;
  .footer-content {
    padding-bottom: 36px;
    border-bottom: 1px solid $grey;
    margin-bottom: 17px;
    display: flex;
    justify-content: space-between;
    .mini-about {
      img {
        margin-bottom: 18px;
      }
      .content {
        font-size: 16px;
        color: $grey;
        max-width: 453px;
        line-height: 1.5;
        b {
          color: white;
        }
      }
    }
    .mini-menu {
      width: 25%;
      max-width: 350px;
      li {
        list-style: none;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
      .title {
        font-size: 20px;
        color: #fff;
        font-weight: 700;
      }
      .list a {
        font-size: 20px;
        color: $grey;
        text-decoration: none;
        transition: .2s;
        &:hover {
          color: white;
          transition: .2s;
        }
      }
    }
  }
  .footer-copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text {
      color: $grey;
      font-size: 20px;
    }
  }
  @media (max-width: $sm-tablet) {
    .footer-content {
      flex-wrap: wrap;
      .mini-about {
        width: 100%;
        margin-bottom: 40px;
        .content {max-width: 640px;}
      }
      .mini-menu {
        width: 35%;
      }
    }
  }
  @media (max-width: $md-mobile) {
    .footer-content {
      flex-wrap: block;
      .mini-menu {
        width: 100%;
        &:last-child {
          margin-top: 24px;
        }
      }
    }
    .footer-copyright {
      display: block;
      justify-content: space-between;
      align-items: center;
      .text {
        color: $grey;
        font-size: 20px;
        &:first-child {
          margin-bottom: 8px;
        }
      }
    }
  }
}

// reusable
.royal-label {
  display: inline-block;
  padding: 10px 16px;
  font-size: 18px;
  font-style: italic;
  color: $blue;
  border-radius: 16px;
  border: 1px solid #fff;
  background-color: rgba(255,255,255, 0.8);
}

.royal-title {
  font-size: 64px;
  font-weight: 700;
  line-height: 1.1;
  color: white;
  span {
    background: linear-gradient(90.46deg, #2958FF -20.28%, #0071F1 16.8%, #0096F2 54.44%, #B8E6F9 89.24%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
  }
}

.app-animate {
  overflow: hidden;
  height: 100vh;

  header {
    transform: translateY(-100%);
    transition: .4s;
  }

  .hero-banner .banner-content {
    opacity: 0;
    transform: translateX(-100%);
    transition: .4s;
  }
  .scroll-down-wrap {
    opacity: 0;
    transform: translateY(-100%);
    transition: .4s;
  }
  .hero-banner .banner-head {
    right: -100%;
    transition: .4s;
  }

  &.active {
    height: auto;
    transition-delay: height .4s;
    header {
      transform: translateY(0);
      transition: .4s;
    }
    .hero-banner .banner-content {
      opacity: 1;
      transform: translateX(0px);
      transition: .8s;
    }
    .scroll-down-wrap {
      opacity: 1;
      transform: translateY(0px);
      transition: .8s;
    }
    .hero-banner .banner-head {
      right: 0;
      transition: .8s;
    }
  }
}