$blue: #2958FF;
$grey: #CACACA;
$background: #08071F;

$sm-mobile: 320px;
$md-mobile: 480px;
$mobile: 640px;
$sm-tablet: 960px;
$tablet: 1024px;
$sm-desktop: 1280px;
$desktop: 1440px;
$wide-screen: 1920px;

@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500;600;700;800;900&display=swap');

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Urbanist';
  font-weight: 400;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #08071F;
}

.main {
  // background-image: url('../assets/images/bg-booking.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px 24px;
}

.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

.btn {
  display: inline-block;
  padding: 10px 20px !important;
  &.btn-line-white {
    border: 1px solid white;
    border-radius: 100px;
    transition: .4s;
    &:hover {
      transition: .4s;
      background-color: white;
      color: #444;
    }
  }
}

.normal-text {
  color: #fff;
  font-size: 16px;
}

.w-10 { width: 10% }
.w-20 { width: 20% }
.w-30 { width: 30% }
.w-40 { width: 40% }
.w-50 { width: 50% }
.w-60 { width: 60% }
.w-70 { width: 70% }
.w-80 { width: 80% }
.w-90 { width: 90% }
.w-full { width: 100% }

.wt-10 {
  @media (max-width: $tablet) { width: 10% }
}
.wt-20 {
  @media (max-width: $tablet) { width: 20% }
}
.wt-30 {
  @media (max-width: $tablet) { width: 30% }
}
.wt-40 {
  @media (max-width: $tablet) { width: 40% }
}
.wt-50 {
  @media (max-width: $tablet) { width: 50% }
}
.wt-60 {
  @media (max-width: $tablet) { width: 60% }
}
.wt-70 {
  @media (max-width: $tablet) { width: 70% }
}
.wt-80 {
  @media (max-width: $tablet) { width: 80% }
}
.wt-90 {
  @media (max-width: $tablet) { width: 90% }
}
.wt-full {
  @media (max-width: $tablet) { width: 100% }
}

.wm-10 {
  @media (max-width: $mobile) { width: 10% }
}
.wm-20 {
  @media (max-width: $mobile) { width: 20% }
}
.wm-30 {
  @media (max-width: $mobile) { width: 30% }
}
.wm-40 {
  @media (max-width: $mobile) { width: 40% }
}
.wm-50 {
  @media (max-width: $mobile) { width: 50% }
}
.wm-60 {
  @media (max-width: $mobile) { width: 60% }
}
.wm-70 {
  @media (max-width: $mobile) { width: 70% }
}
.wm-80 {
  @media (max-width: $mobile) { width: 80% }
}
.wm-90 {
  @media (max-width: $mobile) { width: 90% }
}
.wm-full {
  @media (max-width: $mobile) { width: 100% }
}

@import './components/about.scss';
@import './components/common.scss';
@import './components/experience.scss';
@import './components/herobanner.scss';
@import './components/services.scss';
@import './components/team.scss';